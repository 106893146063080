import React from "react"
import ShanghaiChine from "../assets/chinois/Shanghai China.mp4"

export default function Chinois() {
  return (
    <div>
      <video controls width="30%">
        <source src={ShanghaiChine} type="video/mp4" />
      </video>
    </div>
  )
}
